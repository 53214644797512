import React from "react";
import { Table } from "react-bootstrap";
import { Page, Section } from "../components";

const imgPath = "/images/recruit";

const Schedule = (props) => {  
  return (
    <div style={styles.centered}>
      <h2>전형일정(상시면접 진행)</h2>
      <img src={imgPath + "/schedule.png"} width="70%" alt="schedule"/>
    </div>
  );
};

const DocsApply = (props) => {  
  return (
    <div>
      <h2 style={styles.centered}>지원 방법</h2>
      <hr />
      <h4>제출서류</h4>
      <ul>
        <li>입사지원서 및 자기소개서</li>
        <li>최종학력 졸업증명서,성적증명서,자격증 사본,경력증명서(해당자에 한함)</li>
      </ul>

      <h4>접수방법</h4>
      <ul>
        <li>자유양식 작성 후 이메일 접수</li>
        <li>이메일 : watlogic@watlogic.co.kr</li>
        <li>문의처 : 02-3281-8848(경영지원팀 김아림대리)</li>
      </ul>
    </div>
  );
};

const JobTable = (props) => {    
  return (
    <>
      <h2 style={styles.centered}>채용 분야</h2>
      <Table bordered>
        <thead>
          <tr style={styles.centered}>
            <th>모 집 분 야</th>
            <th>대 상</th>
            <th>응 시 자 격</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={styles.centered}>S/W 개발 엔지니어</td>
            <td style={styles.centered}>신입 및 경력</td>
            <td>
              1. 관련학과 졸업예정자 및 경력자 <br />
              2. ARM Cortex 펌웨어 개발 <br />
              3. 8-bit MCU 펌웨어 개발 <br />
              4. C/C++ 사용 가능자 <br />
              [우대사항] <br />
              &nbsp; - RTOS 관련지식 보유자 <br />
              &nbsp; - IoT Device 개발 경험자
              <br />
              &nbsp; - MCU 개발 경험자
            </td>
          </tr>
          <tr>
            <td style={styles.centered}>경영지원팀</td>
            <td style={styles.centered}>신입 및 경력</td>
            <td>
              1. 유관학과 졸업예정자 및 경력자 <br />
              2. ERP 사용 경험자(더존 등)
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default function About(props) {
  const headerImage = { backgroundImage: "url(/image-header/shake-hands.png)" };
  return (
    <Page title="인재를 구합니다." headerImage={headerImage}>
      <Section bgColor="white">
        <Schedule />
      </Section>

      <Section padding="20px">
        <DocsApply />
      </Section>

      <Section bgColor="white">
        <JobTable />
      </Section>
    </Page>
  );
}

const styles = {    
  centered: { textAlign: "center" },  
};