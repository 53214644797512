const region = 'ap-northeast-2';

const dev = {
  s3: {
    REGION: region,
    BUCKET: '',
  },
  apiGateway: {
    REGION: region,
    HTAG: {
      URL: '',
      API_NAME: '',
    },
  },
  cognito: {
    REGION: region,
    HTAG: {
      USER_POOL_ID: '',
      APP_CLIENT_ID: '',
      IDENTITY_POOL_ID: '',
      IDENTITY_POOL_NAME: '',
    },
  },
};

const prod = dev;

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default {
  // debug: true,
  debug: false,
  ...config,
};
