import React, { useRef } from "react";

export default function Footer() {
  const footer = useRef();
  const styles = {
    wrap: {
      marginTop: "10px",
      width: "100%",
      padding: "50px 0",
      backgroundColor: "#333",
      color: "#ddd",
    },
    copy: { color: "white" },
    companyName: {fontWeight: 'bold'},
    iconSize: { fontSize: "16px" },
  };
  return (
    <div ref={footer} style={styles.wrap}>
      <div className="container">
        <p>
          상호명 : <span style={styles.companyName}>주식회사 와트로직</span> &nbsp;| &nbsp; 
          대표자 : 강현모 &nbsp;| &nbsp; 
          사업자등록번호 : 113-81-81934 &nbsp;| &nbsp; 
          사업장주소 : 서울특별시 금천구 가마산로 96, 1511호 (가산동,대륭테크노타운 8차)
        </p>
       
        <p>
          EMAIL: watlogic@watlogic.co.kr &nbsp;| &nbsp; 
          TEL: 02-3281-8848 &nbsp;| &nbsp; 
          FAX: 02-3281-8857
        </p>
        <p>
          COPYRIGHT<span style={styles.copy}>&copy;</span> 2004 WATLOGIC Co., Ltd. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}
