import React, { useEffect, useRef } from "react";

const { kakao } = window;

const Map = (props) => {
  const myMap = useRef();

  useEffect(() => {
    onLoad();
  }, []);

  function onLoad() {
    // const container = document.getElementById("map");
    const container = myMap.current;

    const options = {
      center: new window.kakao.maps.LatLng(37.48529, 126.87743),
      level: 5,
    };

    const map = new window.kakao.maps.Map(container, options);

    const markerPosition = new kakao.maps.LatLng(37.48529, 126.87743);
    // 마커를 생성합니다
    const marker = new kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(map);
    // 아래 코드는 지도 위의 마커를 제거하는 코드입니다
    // marker.setMap(null);
  }

  const styles = {
    wrap:{
      padding: "20px 0", 
    },
    map: { width: "100%", height: "400px", borderRadius: "10px"},
  };

  return (
    <div style={styles.wrap}>
      <div ref={myMap} id="map" style={styles.map}></div>
    </div>
  )
};

export default Map;
