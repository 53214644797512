import React from "react";
import { Route, Switch } from "react-router-dom";

import AppliedRoute from "./components/AppliedRoute";
// import AuthenticatedRoute from "./components/AuthenticatedRoute";
// import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from "./pages/Home";
import Products from "./pages/Products";
import About from "./pages/About";
import Recruit from "./pages/Recruit";
import NotFound from "./pages/NotFound";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />
      <AppliedRoute
        path="/index.html"
        exact
        component={Home}
        appProps={appProps}
      />
      <AppliedRoute path="/about" exact component={About} appProps={appProps} />
      <AppliedRoute
        path="/products"
        exact
        component={Products}
        appProps={appProps}
      />
      <AppliedRoute path="/recruit" exact component={Recruit} />
      <Route component={NotFound} />
    </Switch>
  );
}
