import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { SectionAnimate2 } from "../components";
import Kakaomap from "../components/Kakaomap";
import "./Home.css";

const imgPath = "/images/home";

const Head = (props) => {
  const myStyle = {
    bgColor: { backgroundColor: "skyblue" },
    bgImage: {
      // backgroundImage: "url(/image-header/jumbotron.jpg)",
      backgroundImage: `url(${imgPath}/jumbotron.jpg)`,
    },
    content: { fontSize: "24px" },
    btn: { width: "200px", margin: "0 20px" },
  };
  return (
    <div
      className="header"
      style={props.loaded ? myStyle.bgImage : myStyle.bgColor}
    >
      <h1 className="title">Watlogic</h1>
      <p style={myStyle.content}>
        주식회사 와트로직은 임베디드 솔루션 전문 회사입니다.
      </p>
      <p style={myStyle.content}>
        당신이 필요한 모든 기술이 저희에게 있습니다..
      </p>
      <br />
      <Link to="/products" className="btn btn-warning" style={myStyle.btn}>
        보유기술 바로가기
      </Link>
      <Link to="/recruit" className="btn btn-info" style={myStyle.btn}>
        인재채용 바로가기
      </Link>
    </div>
  );
};

function CompanyAndTech2() {
  const styles = {
    container: {
      color: "white",
    }
  };
  return (
    <div className="container">
      <div className="row" style={styles.container}>
        <div className="col-sm-6">
          <ImgCard
            title="회사 소개"
            text="임베디드 솔루션 전문 기업"
            bgImg={`url(${imgPath}/img-card-company.png)`}
            link="/about"
          />
        </div>
        <div className="col-sm-6">
          <ImgCard
            title="기술 | 제품 소개"
            text="다양한 MCU 및 AP의 F/W S/W 개발 경험 보유"
            bgImg={`url(/image-header/board.jpg)`}
            link="/products"
          />
        </div>
      </div>
    </div>
  );
}

function Partners() {
  const logoPath = "/images/partners";
  const vendors = [
    { logo: logoPath + "/littelFuse.png", link: "https://www.littelfuse.com" },
    { logo: logoPath + "/logo-adc.png", link: "http://www.adc.co.kr" },
    { logo: logoPath + "/logo-leolsi.gif", link: "http://www.leolsi.com" },
    { logo: logoPath + "/logo-holtek.png", link: "https://www.holtek.com" },
    { logo: logoPath + "/logo-renesas.png", link: "https://www.renesas.com" },
    {
      logo: logoPath + "/logo-telechips.png",
      link: "https://www.telechips.com",
    },
    { logo: logoPath + "/logo-uniquest.png", link: "" },
    { logo: logoPath + "/logo-elite.png", link: "" },
    // { logo: logoPath + "/logo-uniquest.png", link: "http://www.uqc.kr/" },
    // { logo: logoPath + "/logo-elite.png", link: "http://www.elitecsp.com/" },
  ];
  const myStyle = {
    wrap: { width: "100%" },
    list: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      // backgroundColor: "green",
    },
    item: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "200px",      
      height: "200px",      
      margin: "20px",
      // border: '1px solid white',
      borderRadius: "40px",
    },
  };
  return (
    <div className="container">
      <h2 style={styles.centeredTitle}>Semiconductor Partners</h2>
      <hr />
      <div style={myStyle.list}>
        {vendors.map((item, i) => {
          return item.link === "" ? (
            <div className="vendor" style={myStyle.item} key={i}>
              <img src={item.logo} alt={item.logo} width="80%" />
            </div>
          ) : (
            <a
              className="vendor"
              href={item.link}
              style={myStyle.item}
              target="_blank"
              rel="noopener noreferrer"
              key={i}
            >
              <img src={item.logo} alt={item.logo} width="80%" />
            </a>
          );
        })}
      </div>
    </div>
  );
}

function Map() {
  const infoList = [
    {
      icon: "fas fa-map-marker-alt",
      info: "서울특별시 금천구 가마산로 96, 1511호 (가산동,대륭테크노타운 8차)",
    },
    { icon: "far fa-envelope", info: "watlogic@watlogic.co.kr" },
    { icon: "fas fa-phone-square", info: "02-3281-8848" },
  ];

  const myStyles = {
    title: {
      ...styles.centeredTitle,
      color: "white",
    },
    infoWrap: {
      padding: "20px",
      color: "white",
    },
  };

  return (
    <div className="container">
      <h3 style={myStyles.title}>Contact Us</h3>
      <div style={myStyles.infoWrap}>
        {infoList.map((item, i) => {
          return <InfoItem key={i} icon={item.icon} info={item.info} />;
        })}
      </div>
      <Kakaomap />
    </div>
  );
}

export default function Home() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    resetActive()
    setLoaded(true);
  }, []);

  function resetActive(){
    const menus = document.getElementsByClassName('nav-link');
    for(let i = 0 ; i < menus.length ; i++) {
      let el = menus[i];
      el.classList.remove('active');
    }
  }

  return (
    <div className="home">
      <Helmet>
        <title>WatLogic</title>
      </Helmet>
      <Head loaded={loaded} />

      <SectionAnimate2 bgColor="#eeefef">
        <CompanyAndTech2 />
      </SectionAnimate2>

      <SectionAnimate2 bgColor="#fff">
        <Partners />
      </SectionAnimate2>

      <SectionAnimate2 bgColor="steelblue">
        <Map />
      </SectionAnimate2>
    </div>
  );
}

function ImgCard(props) {
  const { title, text, link } = props;
  const styles = {
    card: {
      // width: "100%",
      // height: "60%",
      backgroundColor: "blue",
      backgroundImage: props.bgImg || "url(/images/home/img-card-company.png)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.3)",
      padding: "10%",
      margin: "10px",
    },
    content: { fontSize: "20px" },
    btn: { width: "50%" },
    title: { color: "white" },
  };

  return (
    <div style={styles.card}>
      <h2 style={styles.title}>{title}</h2>
      <p style={styles.content}>{text}</p>
      <Link to={link} className="btn btn-outline-warning" style={styles.btn}>
        자세히 보기
      </Link>
    </div>
  );
}

function InfoItem(props) {
  const styles = {
    flexRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      padding: "5px",
    },
    item: { padding: "0 5%", margin: "0" },
    iconSize: { fontSize: "20px" },
    hr: { borderTop: "1px solid white" },
  };
  return (
    <>
      <div style={styles.flexRow}>
        <p style={styles.item}>
          <i className={props.icon} style={styles.iconSize}></i>
        </p>
        <p style={styles.item}>{props.info}</p>
      </div>
      <hr style={styles.hr} />
    </>
  );
}

const styles = {
  centeredTitle: {
    textAlign: "center",
    paddingBottom: "20px",
    // textDecoration: 'underline'
  },
};
