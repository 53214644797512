import React from "react";
import { Page, Section } from "../components";

const imgPath = "/images/about";

export default function About(props) {
  const headerImage = { backgroundImage: "url(/image-header/building.png)" };
  return (
    <Page title="회 사 소 개" headerImage={headerImage}>
      <Section bgColor="#fff" padding="20px">
        <Overview />
      </Section>

      <Section bgColor="#eee" padding="20px">
        <CeoHello />
      </Section>

      <Section bgColor="#fff" padding="20px">
        <Organization />
      </Section>

      <Section bgColor="#eee" padding="20px">
        <History />
      </Section>

      <Section bgColor="#fff" padding="20px">
        <Certs />
      </Section>
    </Page>
  );
}

const Overview = (props) => {
  const list = [
    {name: '회 사 명', content:'주식회사 와트로직'},
    {name: '설 립 일', content:'2003년 01월'},
    {name: '대표이사', content:'강  현  모'},
    {name: '소 재 지', content:'서울시 금천구 가마산로 96, 1511호 (가산동,대륭테크노타운 8차)'},
    {name: '자 본 금', content:'150 백만원'},
    {name: '주요품목', content:'Embedded software 개발, 반도체 부품(MCU,CPU,LCD,LED)'},
  ]

  const myStyle = {
    item:{
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",      
      borderTop: '1px solid gray'
    },
    subitem: {margin: 0, padding: '5px'}
  }
  return (
    <div>
      <h2 style={styles.centered}>회 사 개 요</h2>
      <hr />
      <div className="row">
        <div className="col-sm-7">
          <p>
            <b>와트로직</b>은 <br />
            기술을 선도하는 기업을 의미하는 World‘s Advanced Technology로
          </p>
          <p>
            <b>'기술을 통해 기업을 지원하고,기업을 통해 인류에 공헌한다.'</b>는
            목표로 설립된 Embedded software 개발 및 반도체 부품 공급 업체입니다.
          </p>
          <p>
            와트로직은 2003년 1월 설립하여 Total Solution 제공을 목표로 하여
            다양한 분야의 제품을 개발 · 생산하였습니다.
          </p>
          <p>
            다양한 경험과 많은 Know-how를 가진 엔지니어가 고객만족을 위해 최선을
            다하며,신뢰할 수 있는 개발 서비스를 기업가치로 삼고 있습니다.
          </p>
          <div>
            <br />            
            {
              list.map((item, i)=>{
                let sub1 = {...myStyle.subitem, width: "20%", textAlign: 'center', fontWeight: '400'}
                let sub2 = {...myStyle.subitem, width: "80%"}
                return (
                  <div style={myStyle.item} key={i}>
                    <p style={sub1}>{item.name}</p>
                    <p style={sub2}>{item.content}</p>
                  </div>
                )
              })
            }

          </div>
        </div>
        <div className="col-sm-5">
          <img src={imgPath + "/building.jpg"} width="100%" alt="building"/>
        </div>
      </div>
    </div>
  );
};

const CeoHello = (props) => {
  const myStyle = {
    wrap: { color: "steelblue", padding: "10px" },
  };
  return (
    <div style={myStyle.wrap}>
      <h2 style={styles.centered}>CEO 인사말</h2>
      {/* <img src={imgPath + "/ceo-photo.jpg"} width="5%" alt="ceophoto"/> */}
      <hr />
      <p>
        와트로직은 ‘고객감동과 상생을 통한 무한가치 창조＇라는 경영이념과
        ‘Technology in everyone’s life ‘ 라는 비전을 품고 끊임없이 발전하고자
        노력하는 임베디드 솔루션 개발 및 반도체 부품 판매 전문기업입니다.{" "}
      </p>

      <p>
        이에 와트로직은 오랜 기간의 반도체 Solution 개발실적과 다양한 경험의
        숙련된 엔지니어를 중심으로 고객의 요구사항을 만족하는 개발전문 회사로의
        역할을 다하고자 합니다.{" "}
      </p>
      <p>
        이제 경제는 제4차 산업혁명의 시대로 AI를 동인으로 IoT · Cloud · Big Data
        · Mobile의 융합으로 파괴적 기술과 ICT와 제조업의 융합으로 상품과
        서비스의 모든 과정에서 모든 것이 연결되고 지능화되는 시대가 되었습니다.
        이러한 기술적 발전은 현재의 인지 후 대응시스템에서 사물과 기계가 스스로
        조절하고, 판단하며 대응하는 예측 후 실행의 단계로 넘어가 사람의 개입없이
        업무와 문제를 처리하게 될 것입니다.
      </p>

      <p>
        와트로직은 이러한 기술과 흐름에 발 맞추어 고객에게 요구되는 Core
        Technology와 미래기술을 중점 개발 하여 고객가치를 증대하고, 선행기술을
        개발함으로써 고객의 진정한 니즈를 이해하는 기업이 되도록 노력
        하겠습니다.
      </p>

      <p>
        또한,와트로직은 사회적 기업으로의 책임을 다하기 위하여 ESG 경영을 통해
        실천하며, 지역발전을 위해 ‘함께 사는 세상’이 되도록 노력하는 선한 기업이
        되도록 저희 와트로직 임직원은 최선의 노력을 하겠습니다.
      </p>

      <div style={styles.flexRow}>
        <p style={styles.ceo}>대표이사 강 현 모</p>
        <img src="/images/about/ceo-sign.png" width="150px" alt="ceo-sign"/>
      </div>
    </div>
  );
};

const History = () => {
  const history = [
    { yr: "21", m: "2021.11", act: "안전콘센트 솔루션 개발" },

    {
      yr: "20",
      m: "2020.10",
      act: "특허등록 : 동작 모드를 설정 가능한 도어락 시스템",
    },
    { yr: "20", m: "2020.08", act: "Holtek 대리점 지정" },

    {
      yr: "19",
      m: "2019.11",
      act: "특허등록 : 양변기용 물탱크 누수 모니터링 시스템",
    },
    {
      yr: "19",
      m: "2019.08",
      act: "특허등록 : 전자 장비의 초기화 동작을 모니터링하기 위한 신호 검출 장치",
    },
    {
      yr: "19",
      m: "2019.05",
      act: "특허등록 : GPS 정보를 제공하는 총기용 블랙 박스 장치",
    },
    { yr: "19", m: "2019.02", act: "특허등록 : 총기용 블랙 박스 장치" },

    {
      yr: "18",
      m: "2018.07",
      act: "특허등록 : 탑승정보 안내 시스템 및 그 방법",
    },
    { yr: "18", m: "2018.06", act: "Elite LED 대리점 지정" },
    { yr: "18", m: "2018.01", act: "Littelfuse MCU 대리점 지정" },

    {
      yr: "17",
      m: "2017.08",
      act: "VCSEL(Vertical Cavity Surface Emission Laser) Touchless Solution 개발",
    },

    { yr: "16", m: "2016.04", act: "경희대학교 가족회사 지정" },

    { yr: "15", m: "2015.03", act: "LEO LSI Design House 및 국내총판 지정" },

    { yr: "14", m: "2014.07", act: "Door Lock Solution 개발" },

    { yr: "13", m: "2013.06", act: "IXYS Design House 및 대리점 지정" },

    {
      yr: "12",
      m: "2012.09",
      act: "Low frequency physical therapy device Solution 개발",
    },

    { yr: "11", m: "2011.03", act: "Blood pressure meter Solution 개발" },

    { yr: "10", m: "2010.10", act: "Abov Design House 및 대리점 지정" },

    { yr: "09", m: "2009.06", act: "Mini Audio Solution 개발" },

    { yr: "08", m: "2008.06", act: "Gas Boiler Solution 개발" },
    {
      yr: "08",
      m: "2008.04",
      act: "LCD Module Solution 개발(2.8/4.3/7/10.1 Inch)",
    },

    { yr: "07", m: "2007.10", act: "BOOREE Multimedia 대리점 지정" },
    { yr: "07", m: "2007.07", act: "Adchips Design House 및 대리점 지정" },

    {
      yr: "06",
      m: "2006.12",
      act: "경영 혁신형 중소기업 인증(서울지방중소기업청)",
    },
    {
      yr: "06",
      m: "2006.03",
      act: "특허등록:시리얼통신으로 제어 가능한 미디어 플레이어모듈 및 그 제어방법",
    },

    { yr: "05", m: "2005.08", act: "벤처기업 등록(서울 지방중소기업청)" },
    { yr: "05", m: "2005.06", act: "ISO 9001:2000 인증획득(ASR)" },

    {
      yr: "04",
      m: "2004.06",
      act: "㈜와트로직 부설연구소 등록(한국산업기술진흥협회)",
    },
    { yr: "04", m: "2004.02", act: "Telechips 대리점 지정" },

    { yr: "03", m: "2003.02", act: "Microchip Design House 지정" },
    { yr: "03", m: "2003.02", act: "삼성전자 MCU Solution Partner 지정" },
    { yr: "03", m: "2003.01", act: "법인설립" },
  ];

  const myStyle = {
    flexRow: {
      ...styles.flexRow,
      justifyContent: "flex-start",
    },
    item: { margin: "7px" },
    itemM: {
      margin: 0,
      paddingLeft: "30px",
      fontWeight: "400",
      color: "steelblue",
    },
    itemAct: { margin: 0, paddingLeft: "30px" },
  };

  return (
    <div>
      <h2 style={styles.centered}>회사 연혁</h2>
      {history.map((item, i) => {
        return (
          <div key={"index" + i} style={myStyle.item}>
            <div style={myStyle.flexRow}>
              <p style={myStyle.itemM}>{item.m}</p>
              <p style={myStyle.itemAct}>{item.act}</p>
            </div>
            <hr style={{ margin: 0 }} />
          </div>
        );
      })}
    </div>
  );
};

const Organization = (props) => {
  return (
    <div style={styles.centered}>
      <h2>조직도</h2>
      <hr />
      <img src={imgPath + "/organization.png"} width="80%" alt="organization"/>
    </div>
  );
};

const Certs = (props) => {
  return (
    <div style={styles.centered}>
      <h2>인증서 및 특허</h2>
      <hr />
      <img src={imgPath + "/cert/cert1.png"} width="80%" alt="cert1"/>
      <img src={imgPath + "/cert/cert2.png"} width="80%" alt="cert2"/>
    </div>
  );
};

const styles = {
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  ceo: { margin: 0, padding: "20px", fontWeight: "bold" },
  centered: { textAlign: "center" },
};
