import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import Routes from "./Routes";
import Footer from "./pages/Footer";
import "./App.css";

function App(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  return (
    <div className="App">
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light"
        className="nav-bg-blue"
      >
        <div className="container">
          <Navbar.Brand>
            {/* <Link to="/">WATLOGIC</Link> */}
            <Link to="/">
              <img
                src="/image-header/logo.png"
                width="200"
                // height="30"
                className="d-inline-block align-top"
                alt="logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <LinkContainer to="/about">
                <Nav.Link>회사소개</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/products">
                <Nav.Link>기술 및 제품</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/recruit">
                <Nav.Link>인재 채용</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <Routes appProps={{ isAuthenticated, setIsAuthenticated }} />
      <Footer />
    </div>
  );
}

export default withRouter(App);
