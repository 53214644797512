import React from "react";
import { Page, Section } from "../components";

const imgPath = "/images/products";

export default function Products(props) {
  const headerImage = { backgroundImage: "url(/image-header/board.jpg)" };
  return (
    <Page title="보유기술 및 개발 이력" headerImage={headerImage}>
      <Section bgColor="#fff">
        <Applications />
      </Section>

      <Section bgColor="#eee">
        <BizModel />
      </Section>

      <Section bgColor="#fff">
        <TechEvolution />
      </Section>

      <Section bgColor="#eee">
        <DevelopRecord />
      </Section>

      <Section bgColor="#fff">
        <StrongPoint />
      </Section>
    </Page>
  );
}

const Applications = (props) => {
  return (
    <div style={styles.centered}>
      <h2>보유 기술</h2>
      <hr />
      <img src={imgPath + "/applications.png"} width="70%" alt="application"/>
    </div>
  );
};

const TechEvolution = (props) => {
  return (
    <div style={styles.centered}>
      <h2>단계별 기술 및 Solution 확보 전략</h2>
      <hr />
      <img src={imgPath + "/tech-evolution.png"} width="60%" alt="tech evol"/>
    </div>
  );
};

const BizModel = (props) => {
  const list = [
    "각종 인증규격에 맞는 Total Solution을 (KS,CE 등) 제공합니다.",
    "고객 특성 및 요구에 따른 Business 가능합니다.",
    "고객의 요구에 따른 필요 요소기술 개발지원 가능합니다.(Hardware개발,Software 개발,Total Solution 개발)",
  ];
  const myStyle = {
    item: { paddingLeft: "10%", fontSize: "1.4rem", color: "steelblue" },
  };
  return (
    <div>
      <h2 style={styles.centered}>비즈니스 모델</h2>
      <hr />
      {list.map((item, i) => {
        return (
          <p style={myStyle.item} key={i}>
            <i className="fas fa-chevron-circle-right"></i>&nbsp; &nbsp;&nbsp;
            {item}
          </p>
        );
      })}
    </div>
  );
};

const DevelopRecord = (props) => {
  return (
    <div style={styles.centered}>
      <h2>개발 이력</h2>
      <hr />
      <img src={imgPath + "/dev-records.png"} width="80%" alt="dev record"/>
    </div>
  );
};

const StrongPoint = (props) => {
  const list = [
    "다양한 어플리케이션 분야에서 개발 경험을 보유하고 있습니다.",
    "폭넓은 경험을 가진 엔지니어들이 고객의 어려움을 해결합니다.",
    "상용화 이후에 발생되는 문제들에 대해 다양한 애프터서비스를 제공합니다.",
    "필드에서 요구하는 다양한 차원에서 지원을 약속합니다.",
  ];

  const myStyle = {
    item: { paddingLeft: "10%", fontSize: "1.4rem", color: "steelblue" },
  };
  return (
    <div>
      <h2 style={styles.centered}>와트로직의 장점</h2>
      <hr />
      {list.map((item, i) => {
        return (
          <p style={myStyle.item} key={i}>
            <i className="fas fa-chevron-circle-right"></i>&nbsp; &nbsp;&nbsp;
            {item}
          </p>
        );
      })}
    </div>
  );
};

const styles = {
  centered: { textAlign: "center" },
};
