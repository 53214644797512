import React, { useState, useEffect, useRef } from "react";
import { debug } from "../settings";

/**
 *
 * @param {Object} props.style
 * @param {String} props.padding
 * @param {String} props.bgColor
 * @param {Component} props.children
 * @returns
 */
export function Section({ style, padding, bgColor, children }) {
  const styles = style || {
    margin: "10px 0 0",
    padding: padding || "20px 0",
    width: "100%",
    backgroundColor: bgColor || "#eee",
  };
  return <div style={styles}>{children}</div>;
}

/**
 *
 * @param {Object} props.style
 * @param {String} props.padding
 * @param {String} props.bgColor
 * @param {Component} props.children
 * @returns
 */
// export function SectionAnimate({ style, padding, bgColor, children }) {
//   const [animate, setAnimate] = useState(false);

//   let intv;
//   const myRef = useRef();

//   useEffect(() => {
//     onLoad();
//     return function () {
//       clearInterval(intv);
//     };
//   });

//   function onLoad() {
//     intv = setInterval(() => {
//       let h = (window.innerHeight * 3) / 4;
//       const rect = myRef.current.getBoundingClientRect();
//       // console.log("rect:", rect);
//       // console.log("h, top:", h, rect.top);
//       if (rect.top < h) onSeen();
//     }, 200);
//   }

//   function onSeen() {
//     clearInterval(intv);
//     setAnimate(true);
//   }

//   const styles = {
//     wrap: style || {
//       margin: "10px 0 0",
//       padding: padding || "40px 0",
//       width: "100%",
//       backgroundColor: bgColor || "skyblue",
//     },
//   };

//   return (
//     <div ref={myRef} style={styles.wrap}>
//       <div className={animate ? "slide" : "slideanim"}>{children}</div>
//     </div>
//   );
// }

/**
 *
 * @param {String} props.title
 * @param {Object} props.headerImage
 * @param {Component} props.children
 * @returns
 */
export function Page({ title, children, headerImage }) {
  // const [loaded, setLoaded] = useState(false);
  const [minH, setMinH] = useState(0);

  const page = useRef();

  useEffect(() => {
    function onLoad() {
      // setLoaded(true);
      setMinHeight();
    }
    onLoad();
  }, []);

  function setMinHeight() {
    let pageRect = page.current.getBoundingClientRect();
    let contentMinH =
      window.innerHeight - (document.body.clientHeight - pageRect.height);

    //
    // always same unless header and footer are not changed
    //
    if (contentMinH > 0) setMinH(contentMinH);

    if (debug) {
      console.log("window.innerHeight", window.innerHeight);
      console.log("body.clientHeight ", document.body.clientHeight);
      console.log("page.height       ", pageRect.height);
      console.log("contentMinH       ", contentMinH);
    }
  }

  const styles = {
    page: {
      // marginTop: "10px",
      minHeight: minH + "px",
    },
    header: {
      backgroundPosition: "0% 50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      color: "white",
      padding: "30px 25px",
      textAalign: "center",
      backgroundColor: "skyblue",
    },
    headerBg: headerImage || {},
    title: { fontWeight: 400 },
    content: { marginTop: "10px" },
  };

  return (
    <div ref={page} style={styles.page}>
      <div style={{ ...styles.header, ...headerImage }}>
        <h1 style={styles.title}>{title}</h1>
      </div>
      <div className="container" style={styles.content}>
        {children}
      </div>
    </div>
  );
}

/**
 * 
 * @Description use Scroll Event
 */
export function SectionAnimate2({ style, padding, bgColor, children }) {
  const [animate, setAnimate] = useState(false);
  const myRef = useRef();

  useEffect(() => {
    onScroll();
    window.addEventListener("scroll", onScroll);

    return function () {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  function onScroll(e) {
    let h = (window.innerHeight * 3) / 4;
    const rect = myRef.current.getBoundingClientRect();
    if (rect.top < h) setAnimate(true);
  }

  const styles = {
    wrap: style || {
      margin: "10px 0 0",
      padding: padding || "40px 0",
      width: "100%",
      backgroundColor: bgColor || "skyblue",
    },
  };

  return (
    <div ref={myRef} style={styles.wrap}>
      <div className={animate ? "slide" : "slideanim"}>{children}</div>
    </div>
  );
}
